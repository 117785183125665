import Vue from "vue";

/*TITLE*/
document.title = "Montemar Garden | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Montemar Garden";
Vue.prototype.$subtitle = "EXCLUSIVIDAD Y BIENESTAR";

/*INTRO*/
Vue.prototype.$promoter = "Montemar Garden";
Vue.prototype.$introSubtitle = "EXCLUSIVIDAD Y BIENESTAR";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "vela-natural-100x100--20230116090146.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "vela-natural-100x100-verbier-sand-45x120-samui-verbier-sand-45x120--20230116090157.jpg";
Vue.prototype.$image_bath2 = "vela-natural-100x100-verbier-sand-45x120-thao-verbier-sand-60x60--20230116090119.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "0-facade--20221124091723.jpeg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "vela-natural-100x100--20230116090146.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "vela-natural-100x100-verbier-sand-45x120-samui-verbier-sand-45x120--20230116090157.jpg",
  },
  {
    src: "vela-natural-100x100-prada-acero-45x120-spiga-prada-acero-45x120--20230116090105.jpg",
  },
  {
    src: "vela-natural-100x100-metropolitan-caliza-45x120-ice-nebraska-tea-45x120--20230116090111.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "vela-natural-100x100-verbier-sand-45x120-thao-verbier-sand-60x60--20230116090119.jpg",
  },
  {
    src: "vela-natural-100x100-persia-45x120--20230116090125.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "0-facade--20221124091723.jpeg",
  },
  {
    src: "1-facade--20221124091723.jpeg",
  },
  {
    src: "2-facade--20221124091723.jpeg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20don%20juan%20manuel%2046%20torremolinos%2029620",
    text: "C/ Don Juan Manuel, 46. Torremolinos. 29620",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=plaza%20costa%20del%20sol%2015%201izq%20torremolinos%2029620",
    text: "Plaza Costa del Sol, 15 1izq. Torremolinos. 29620",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952000456",
    text: "952000456",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@primehouse.es",
    text: "info@primehouse.es",
  },
];
